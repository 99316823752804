import {
  type HeadlineResponsePagedData,
  v1CategoriesHeadlinesPaginatedDetail,
} from '@on3/api';
import { ErrorData } from '@on3/ui-lib/api/schema/custom-contracts';
import { useAuth } from '@on3/ui-lib/src/contexts/AuthProvider';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { externalApi } from '@on3/ui-lib/utils/api';
import { AxiosError } from 'axios';
import { MobileMPU } from 'components/Ads/MobileMPU';
import { MPU } from 'components/Ads/MPU';
import styles from 'components/Headlines/ArticleCarousel/Feed.module.scss';
import { ArticleLarge } from 'components/Headlines/ArticleLarge/ArticleLarge';
import { Text } from 'components/UI/Typography/Text';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { initializeAds } from 'utils/ads/ads';

export const ArticlesList = ({ list = [] }: HeadlineResponsePagedData) => {
  const { user } = useAuth();
  const [hasMore, setHasMore] = useState(true);
  const [feedData, setFeedData] = useState(list || []);
  const { currentSite } = useSite();
  const [page, setPage] = useState(2);
  const [adsInitialized, setAdsInitialized] = useState(true);

  const main = currentSite?.siteCategories?.find(
    (category) => category.url === '',
  );
  const passedTeam = currentSite?.isNational ? 'on3' : currentSite?.slug;
  const fetchData = useCallback(async () => {
    try {
      const params = {
        page: page || 0,
        category: main?.categoryKey || 0,
      };

      const newData = await v1CategoriesHeadlinesPaginatedDetail(
        externalApi,
        params,
      );

      if (newData?.list && newData?.list?.length > 0) {
        setFeedData([...feedData, ...newData.list]);
        setPage(page + 1);
      } else {
        setHasMore(false);
      }
    } catch (e: unknown) {
      const { response, message }: AxiosError<ErrorData> =
        e as AxiosError<ErrorData>;

      console.error(
        'Load more issue on Feed Home: ',
        response?.data?.message || message,
      );
    } finally {
      setAdsInitialized(false);
    }
  }, [feedData, main?.categoryKey, page]);

  useEffect(() => {
    if (!adsInitialized && feedData.length) {
      initializeAds({
        pageType: 'home',
        template: 'Feed',
        team: passedTeam,
        siteType: currentSite?.type,
        incUnits: 5,
        userStatus: user?.a ? user?.st : 'guest',
        siteKey: currentSite?.key,
        resetInc: false,
      });
      setAdsInitialized(true);
    }
  }, [adsInitialized, currentSite, passedTeam, user, feedData.length]);

  if (feedData.length < 1) {
    return null;
  }

  return (
    <div className={styles.articlesContainer}>
      <Text className={styles.articlesListDate}>
        {feedData[0]?.postDate?.split('T')[0]}
      </Text>
      <InfiniteScroll
        className={styles.largeArticleList}
        dataLength={feedData.length}
        hasMore={feedData?.length > 0 && hasMore}
        loader={<h4>Loading...</h4>} //TODO: replace with on3 spinner
        next={fetchData}
        scrollThreshold="800px"
      >
        {feedData.map((article, index) => {
          return (
            <React.Fragment key={article.key}>
              <ArticleLarge {...article} />
              {(index + 1) % 5 === 0 && index > 0 && (
                <>
                  <MPU
                    className={styles.videoAd}
                    id={`mpu_inc-${(index + 1) / 5}`}
                    incontent
                  />
                  <MobileMPU
                    className={styles.videoAd}
                    id={`mobile_mpu_inc-${(index + 1) / 5}`}
                    incontent
                  />
                </>
              )}
            </React.Fragment>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};
